#Weinland {

  .weinladndsvgRegion {
    cursor: pointer;
  }

  .weinladndsvgRegion.region-active .weinlandsvgRegionShape {
    fill: #CC585C !important;
  }

  .weinlandsvgRegionText {
    pointer-events: none;
    opacity: 0;
    transition: opacity 300ms linear;
  }

  .weinladndsvgRegion:hover .weinlandsvgRegionText {
    opacity: 1;
  }

  .weinladndsvgRegion:hover .weinlandsvgRegionShape {
    fill: #CC585C !important;
  }

  @include media-breakpoint-down(lg) {
    #Geografie-Text {
      display: none;
    }
  }

  .Ortskreis{
    stroke-width: 2px;
    stroke: #000000;
  }

}
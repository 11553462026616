.Loader{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 7777;
  background: #FFFFFF;
}
.LoaderIcon{
  animation: ROTATION 2s infinite linear;
  width: 3rem;
  height: 3rem;
}
@keyframes ROTATION{
  0%   { transform: rotate(0); }
  100% { transform: rotate(360deg); }
}
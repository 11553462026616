.FilterInterface{
  position: relative;
  z-index: 5555;
}

.interface-button{
  padding: 0 0.5rem !important;
  height: 3rem;
  display: flex;
  align-items: center;
  width: 100%;
}

.FilterInterface-Body{
  padding-top: 1rem;
  //padding-bottom: 1rem;

}


.FilterInterface-MobileHeader{
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #000000;
  background-color: #FFFFFF;
  display: block;
  @include media-breakpoint-up(lg){
    display: none;
  }

  .interface-button{
    background-color: #F2F2F2;
  }
}







.FilterInterface-BodyClose-Btn{
  display: none;
}
@include media-breakpoint-down(md){
  .FilterInterface-Body{
    background-color: #FFFFFF;
    border-bottom: 1px solid black;
    position: absolute;
    top:0;
    left: 0;
    right: 0;
    display: none;
  }
  .FilterInterface-Body.open{
    display: block;
  }
  .FilterInterface-BodyClose-Btn{
    justify-content: center;
    display: block !important;
  }
}



.FilterInterface-Body{

  .Filter-Container{
    position: relative;
    margin-bottom: 1rem;
  }
  .Filter-Container.active .Filter-HeadButton{
    background-color: #C7C7C7;
  }
  .Filter-Head{
    position: relative;
  }
  .Filter-HeadButton {
    background-color: #F2F2F2;
  }

  .Filter-Body{
    top: 100%;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 6666;
    padding-top: 0.25rem;
    background-color: #C7C7C7;
    max-height: 24.5rem;
    overflow-y: auto;
  }
  .Filter-BodyOption{
    width: 100%;
    padding: 0 .25rem .25rem .25rem ;

    button{
      background-color: #F2F2F2;
    }
  }
  .Filter-BodyOption button.active{
    border: 1px solid #000000;
    background-color: #F2F2F2;
  }

}

.Filter-Container.open{
  .Filter-HeadButton{
    background-color: #C7C7C7;
  }
}

.Filter-OptionCheckbox{
  width: 1rem;
  height: 1rem;
  border-radius: .3rem;
  background-color: #C7C7C7;
  margin-right: .5rem;
  position: relative;
  svg{
    padding: .1rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;

  }
}

.Filter-BodyOption button.active .Filter-OptionCheckbox svg{
  display: block;
}


.FilterInterfaceFooter{
  border-bottom: 1px solid #C7C7C7;
}
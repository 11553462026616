.WarenkorbView{
  margin-top: 2rem;
  padding-bottom: 6rem;
}

.WarenkorbGenralAction{
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
  .btn{
  }
}

.WarenkorbTotal{
}
.WarenkorbTotal-Total{
  border-top: 1px solid #000000;
  margin-top: .5rem;
  padding-top: .5rem;
}
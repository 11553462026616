.WeinMeta{
  .Icon-Block{
    padding-left: 0;
  }
}

.WeinMeta-Headline{
}

.WeinMeta-Body{
  ul{
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;
  }

  border-top: 1px solid #626262;
  border-bottom: 1px solid #626262;
  padding-top:.5em;
  padding-bottom:.5em;
  margin-bottom: .5em;

  li{
    display: flex;
    flex-wrap: nowrap;
    div{
      padding-top: .15rem;
    }
  }

}

.WeinMeta-Footer{

}
.button-reset, .button-reset:focus, .button-reset:hover {
  padding: 0;
  outline: none;
  border: 0;
  text-decoration: none;
}

.button-reset{
  background: none;
}

.btn {
  outline: 0;
  text-align: left;
}

.btn-dark {
  background: #000000;
}

.btn-light {
  background: #F2F2F2;

  &:hover {
    background: #E5E5E5;
  }
}

.button {
  display: inline-block;
  padding: .5rem !important;
  background: #000000 !important;
  color: #FFFFFF;
  user-select: none;
}

.button-dark {
  display: inline-block;
  color: #FFFFFF;
}

.button-dark.disabled {
  background: #C7C7C7 !important;
}

.button-square {
  width: 2rem;
  height: 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
}


// buttons
.button-standard {
  padding: 1rem 2rem !important;
}



// button colors
.button-dark {
  background: #000000;
  color: #FFFFFF;
  &:hover, &:focus {
    background-color: #333;
    color: #FFFFFF;
  }
}

.button-light{
  background: #F2F2F2;
  color: black;
  &:hover, &:focus {
    background-color: #F2F2F2;
    color: #000000;
  }
}

.button-transparent{
  background: transparent !important;
}
.SimpleLoadIndicator{
  position: relative;
}

.SimpleLoadIndicator-Inner{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;
}

.SimpleLoadIndicator.loading .SimpleLoadIndicator-Inner,
.SimpleLoadIndicator.error .SimpleLoadIndicator-Inner {
  position: relative;
}
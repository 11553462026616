html {
  overflow-y: scroll;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.PageContent{
  padding-top: 50px;
  background-color: #FFFFFF;
  min-height: 80vh;
  position: relative;
}

svg{
  path, circle, rect, polygon, polyline {
    stroke-width: 0;
  }
}
.PageHeader-Desktop{

  @include media-breakpoint-down(sm){
    display: none !important;
  }

  background-color: #FFFFFF;
  border-bottom: 2px solid #000000;
  font-size: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  z-index: 9999;


  .Navigation-Main{
    text-align: center;
  }
  .Navigation-Sub{
    position: absolute;
    top: 0;
    right: 0;
    li{
      margin-right: 0;
      margin-left: .5rem;
    }
  }
}

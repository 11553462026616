.ShoppingcartInterface{
}

.ShoppingcartInterface-AmountBtn{
  width: 2rem;
  height: 2rem;
  display: inline-block;
  text-align: right;
}

.ShoppingcartInterface-Amount{
  width: 3rem;
  height: 2rem;
  display: inline-block;
  vertical-align: top;
  padding: 0;
  margin: 0;
  border: none;
  background: #C7C7C7;
  text-align: right;
}

/* For Firefox */
input[type='number'] {
  text-align: center;
  &:focus{
    outline: none;
  }
  -moz-appearance:textfield;
}
/* Webkit browsers like Safari and Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.ShoppingcartInterface-Header{
  padding-bottom: 1rem;
  border-bottom: 1px solid #C7C7C7;
}

.ShoppingcartInterface-Row{
  padding-top: 1rem;
  padding-bottom: 1rem;
  @include media-breakpoint-down(lg){
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

.ShoppingcartInterface-Footer{
  display: flex;
  justify-content: space-between;
}
.WeinImage{
  background: #F2D37A;
  position: relative;
  padding-top: 100%;
  img{
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: 1rem;
    transition: opacity 300ms linear;
  }
  &.loading{
    img{ opacity: 0; }
  }
  &.loaded{
    img{ opacity: 1; }
  }
}

.WeinImage-Like{
  position: absolute;
  top:0;
  left:0;
}

.WeinTags{
  position: absolute;
  top:0;
  right:0;
  z-index: 200;
  display: flex;
  font-size: 7px;
}

.WeinTag{
  line-height: .85;
  margin-right: .5em;
  margin-top: .5em;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2em;
  padding-left: 1em;
  padding-right: 1em;
  background-color: #FFFFFF;
  border-radius: 1em;
}
.WeinView{
  margin-top: 4rem;
  margin-bottom: 2rem;
  @include media-breakpoint-down(sm){
    margin-top: 1rem;
  }
}

.WeinView-Image{
  margin-bottom: 1rem;
}
.WeinView-Headline{
  margin-bottom: 1rem;
}
.WeinView-Meta{
  margin-bottom: 4rem;
}
.WeinView-Shopping{
  margin-bottom: 4rem;
}
.WeinView-Beschreibung{
  margin-bottom: 4rem;
}
.WeinView-Fakten{
  margin-bottom: 4rem;
  .container-fluid{
    padding:0;
  }
  .FaktenZeile{
    padding-bottom: .5rem;
    margin-bottom: 1rem;

    .FaktenHead{
      border-bottom: 1px solid black;
      padding: .25rem;
    }
    .FaktenInfo{
      padding: .25rem;
    }
  }
}

.WeinView-Links{
  margin-bottom: 4rem;
  a{
    margin-bottom: 1rem;
    display: block;
    background-color: #F2D37A;
    padding: 1rem;
    text-decoration: none;
  }
}
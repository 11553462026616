.Portrait {
  padding-top: 4rem;
  padding-bottom: 6rem;
}


.SubHeader {
  margin-bottom: 4rem;
  text-align: center;
}


.SubHeader-Icon {
  width: 3rem;
  height: 3rem;
  margin-right: auto;
  margin-left: auto;
  svg {
    width: 100%;
    height: 100%;
  }
}



.SubContent {
  .SubFacts {
    .FaktenZeile {
      padding-bottom: .5rem;

      .FaktenHead {
        border-bottom: 1px solid black;
        padding: .25rem;
      }

      .FaktenInfo {
        padding: .25rem;
      }
    }
  }

  .SubText {
    width: 55%;
    @include media-breakpoint-down(xs) {
      width: 80%;
      margin-top: 2rem;
    }
  }
}

.SubImages {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;

  img {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 90vh;
    margin-bottom: 2rem;
  }
}

.bodegaLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;

  div {
    padding: .25rem;
    border-bottom: 1px solid black;

    &:hover {
      cursor: pointer;
    }
  }
}
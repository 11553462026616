.Ueberuns {
  padding-bottom: 4rem;
}

.UeberunsBilder {
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.UeberunsBody {
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 6rem;
}


.UnsTeam {

  img {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    z-index: 200;
  }

  margin-top: 4rem;
  margin-bottom: 6rem;

  .UnsTeam-Row {
    margin-top: 6rem;
    margin-bottom: 8rem;
  }

  @include media-breakpoint-down(sm) {
    margin-top: 4rem;
    margin-bottom: 6rem;
    .UnsTeam-Row {
      margin-top: 3rem;
      margin-bottom: 6rem;
    }
    .UnsTeam-Text {
      margin-top: 2rem;
    }
  }

  .UnsTeam-Text {
    padding-left: 4rem;
    padding-right: 4rem;
    position: relative;
    z-index: 100;
  }

  .UnsTeam-Backgrounds {
    position: absolute;
    top: -30%;
    width: 50%;
    left: 25%;
  }

  .UnsTeam-TextContent {
    position: relative;
    z-index: 200;
  }
}

.LadenBilder {
  margin-bottom: 6rem;

  img {
    width: 100%;
    height: auto;
  }
}

.UeberunsPartner{
  *{
    text-align: center;
  }
    margin-bottom: 6rem;
}
.IntroImageSection{
  padding: 0;
  position: relative;
  height: auto;
}

.IntroBild{
  width: 100%;
  height: auto;
  position: relative;
  display: block;
}


.FullscreenVideo{
  width: 100%;
  height: auto;
  position: relative;
  display: block;
}

.IntroLogo{
  img{
    display: block;
    @include media-breakpoint-down(md){
      width: 75%;
      margin-left: 12.5%;
    }
  }
}

.IntroHeadline{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.IntroTextSection{
  text-align: center;
}


.SocialMediaBild{
  display: block;
}

.IntroTextButton{
  margin-top: 3rem;
  margin-bottom: 6rem;
}
.WeinlandView {
}


.Weinland-Description {
  padding-top: 4rem;
  padding-bottom: 4rem;
  text-align: center;
  p {
    margin-top: 3rem;
  }
}


.Map-Container {
  background-color: #ffdf7e;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  svg{
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  @include media-breakpoint-up(xl) {
    height: 70vh;
  }

  .Searchbar {
    width: 30%;
    @include media-breakpoint-down(md) {
      width: 60%;
    }
    @include media-breakpoint-down(xs) {
      width: 80%;
    }

    input {
      border-radius: 3rem;
      padding: .5rem;
      border: none;

      &:focus {
        outline: none;
      }
    }
  }
}




.WeinlandSearchResult.open{
  height: 50vh;
}
.WeinlandSearchResult.closed{
  height: 0;
}





.WeinlandSearchResult {
  transition: height 300ms ease;
  overflow-y: auto;

  .content {
    height: auto;
  }

  .Select {
    .noSearchResult {
      color: #dc3545;
    }
    padding-bottom: 4rem;
  }

  .Select-Region {
    padding-top: 4rem;
  }

  .Select-Bodega {
    padding-top: 4rem;
  }

  .Select-Header {
    border-bottom: 1px solid $black;
    padding-bottom: .25rem;
    margin-bottom: 0;
  }

  .Select-Item {
    border-bottom: 1px solid $black;
    padding-bottom: .25rem;
    padding-top: .25rem;
    cursor: pointer;

    &:hover {
      background: #F2D37A;
    }
  }

}
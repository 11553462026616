h1, .headline-1 {
  font-size: 50px;
  line-height: 60px;
  font-family: meno-banner, serif;
  font-weight: 900;
  font-style: normal;
}

h2, .headline-2 {
  font-size: 32px;
  line-height: 39px;
  font-family: meno-banner, serif;
  font-weight: 900;
  font-style: normal;
}

h3, .headline-3 {
  font-size: 20px;
  line-height: 24px;
  font-family: meno-display, serif;
  font-weight: 800;
  font-style: normal;
}

.fliesstext-gross {
  font-size: 18px;
  line-height: 25px;
  font-family: meno-text, serif;
  font-weight: 600;
  font-style: normal;
}

.fliesstext-klein {
  font-size: 16px;
  line-height: 22px;
  font-family: meno-text, serif;
  font-weight: 600;
  font-style: normal;
}


// Aktiv

.aktiv-bold {
  font-size: 13px;
  line-height: 18px;
  font-family: aktiv-grotesk-extended, sans-serif;
  font-weight: 700;
  font-style: normal;
}

.aktiv-regular {
  font-size: 13px;
  line-height: 18px;
  font-family: aktiv-grotesk-extended, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.aktiv-btn-bold {
  font-size: 13px;
  line-height: 18px;
  font-family: aktiv-grotesk-extended,sans-serif;
  font-weight: 700;
  font-style: normal;
}

.aktiv-btn-regular {
  font-size: 13px;
  line-height: 18px;
  font-family: aktiv-grotesk-extended,sans-serif;
  font-weight: 400;
  font-style: normal;
}



@include media-breakpoint-down(md){
  h1, .headline-1 {
    font-size: 32px;
    line-height: 39px;
  }

  h2, .headline-2 {
    font-size: 26px;
    line-height: 32px;
  }

  h3, .headline-3 {
    font-size: 20px;
    line-height: 24px;
  }

  .fliesstext-gross {
    font-size: 20px;
    line-height: 27px;
  }

  .fliesstext-klein {
    font-size: 18px;
    line-height: 25px;
  }
  .aktiv-bold {
    font-size: 13px;
    line-height: 18px;
  }

  .aktiv-regular {
    font-size: 13px;
    line-height: 18px;
  }

  .aktiv-btn-bold {
    font-size: 13px;
    line-height: 18px;
  }

  .aktiv-btn-regular {
    font-size: 13px;
    line-height: 18px;
  }
}

.inline-link{
  text-decoration: underline;
}

.redactor-body a{
  text-decoration: underline !important;
}
.MobileNav{
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  @include media-breakpoint-up(md){
    display: none !important;
  }
}
.MobileNav--exited{
  bottom: auto !important;
}
.MobileNav--entered{
  background-color: #FFFFFF;
}

.MobileNav-Toggler{
  z-index: 9999;
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
}



.MobileNav-LogoContainer{
  z-index: 9998;
  height: 48px;
  width: 100%;
  background: #FFFFFF;
  transition: height 300ms ease;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 7px;
}

.MobileNav-LogoContainer img{
  display: block;
  height: 30px;
  width: auto;
}
.MobileNav--open .MobileNav-LogoContainer{
  height: 100px;
}



.MobileNav-LinksWrapper{
  z-index: 9997;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 48px;
  transition: padding-top 300ms ease;
}

.MobileNav--open .MobileNav-LinksWrapper{
  padding-top: 100px;
}

.MobileNav-LinksWrapperEnd{
  border-bottom: 2px solid black;
}

.MobileNav-LinkItem{
  transition: height 300ms ease, opacity 300ms linear;
  height: 0;
  opacity: 0;
  overflow: hidden;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MobileNav--open .MobileNav-LinkItem{
  height: 20%;
  opacity: 1;
}


.MobileNav-LinkItem .active{
  border-bottom: 2px solid black;
}
.PageFooter {
  background-color: #F2F2F2;
}
.PageFooter-Content {
  box-sizing: border-box;
  padding-top: 4rem;
  padding-bottom: 4rem;

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  li {
    //margin-right: 1rem;
  }
}

.GastroLogin{
  @include media-breakpoint-down(xs){
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  form{
    position: relative;
    margin-left: 0;
    transition: 200ms ease;
    height: 2rem;
    button{
      margin-right: .25rem;
    }
    input[type=text]{
      border: none;
      background-color: unset;
      border-bottom: 2px solid black;
      &:focus{
        outline: none;
      }
    }
  }
  .toggleSwitch {
    z-index: 1000;
    position: relative;
    box-sizing: border-box;
    width: 5rem;
    height: 2rem;
    padding-left: .25rem;
    padding-right: .25rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover{
      cursor: pointer;
    }
    .innerSwitch {
      transition: 200ms ease;
      position: absolute;
      height: 1.37rem;
      width: 1.37rem;
      background-color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &.loggedOut{
    .toggleSwitch{
      background-color: #C7C7C7;
      .innerSwitch{
        left: .4rem;
      }
    }
    form{
      opacity: 0;
      margin-top: -1rem;
    }
  }
  &.startLogin{
    .toggleSwitch{
      background-color: #000000;
      .innerSwitch{
        left: 1.76rem;
        &.shake{
          animation: shake 200ms ease;
        }
      }
    }
    form{
      opacity: 1;
      margin-top: .5rem;
      input[type=text]{
        &.wrong{
          border-bottom: 2px solid #dc3545;
        }
      }
    }
  }
  &.loggedIn{
    .toggleSwitch{
      background-color: #20c997;
      .innerSwitch{
        left: 3.3rem;
      }
    }
    form{
      opacity: 0;
      margin-top: -1rem;
    }
  }
}

@keyframes shake {
  0% { left: 1.7rem }
  16% { left: 1.5rem }
  33% { left: 1.9rem }
  50% { left: 1.5rem }
  67% { left: 1.9rem }
  84% { left: 1.5rem }
  100% { left: 1.7rem }
}

.Aktuelles{
  margin-top: 8rem;
  margin-bottom: 4rem;
}

.AktuellesSectionHeadline{
  margin-bottom: 3rem;
}

.AktuellesEntry{
  margin-bottom: 2rem;
  background-color: #FFF;
  box-shadow: 0px 3px 25px #00000029;
}




.AktuellesEntryContent{
  text-align: center;

}

.AktuellesEntry-Date{
  color: #C7C7C7;
  padding: 1rem;
}

.AktuellesEntry-Headline {
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  padding: 0 1rem;
}

.AktuellesEntry-Bild{
  display: block;
  width: 100%;
  margin-bottom: 2rem;
}

.AktuellesEntry-Body{
  padding: 1rem;
  padding-bottom: 2rem;
}

.AktuellesEntry-Link{
  background-color: #FFD576;
  display: block;
  padding: 1rem !important;
  text-align: center;
}


.SubNav{
  margin-bottom: 4rem;
  border-bottom: 2px solid black;
  padding: 1rem;
  padding-bottom: 0;
  height: 50px;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  .SubNav-Item{
    display: block;
    border-bottom: 4px solid #FFFFFF;
    margin-left: 2rem;
    margin-right: 2rem;
    text-decoration: none!important;
    padding-bottom: .3em;
  }
  .SubNav-Item.active{
    border-color: #000000;
  }


}

.BestellungsanfrageView{
  margin-top: 4rem;
  padding-bottom: 6rem;
}
.BestellungsanfrageForm{
  margin-top: 4rem;
}
.WarenkorbRow{
  margin-bottom: 2rem;
}
label {
  color: black;
  font-size: 0.8rem;
  width: 100%;
}
input[type=text] {
  width: 100%;
  height: 2rem;
  box-sizing: border-box;
  border: 1px solid black;
  margin-bottom: 1rem;
}
input[type=text]:focus {
  width: 100%;
  box-sizing: border-box;
  border: 3px solid grey;
}
.optional {
  color: grey;
}
.Incomplete-Warning{
  color: red;
}



.Lieferkosten{
}
.Lieferkosten-Option{
  width: 100%;
  margin-top: 4rem;

  @include media-breakpoint-up(md) {
    width: 50%;
  }
}
@import "./Loader.scss";

.LikeButton{
  padding: .4em;
  transition: transform 100ms ease-out;
  transform-origin: center center;
  &.liked{
    #Heart{
      fill: orangered;
    }
  }
  &:hover{
    transform: scale(1.1);
  }
  svg{
    width: 2rem;
    height: 2rem;
  }
}
#Heart{
  fill: none;
}



.Icon-Block{
  display: block;
  padding: .3rem;
  svg{
    display: block;
    height: 1.2rem;
    width: 1.2rem;
  }
}

.Icon-Inline{
  display: inline;
  padding: .3rem;
  vertical-align: center;
  svg{
    display: inline;
    height: 1em;
    width: 1em;
  }
}
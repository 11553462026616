.SocialMediaImageSection{
  padding: 0;
  position: relative;
  height: auto;
}

.SocialMediaBild{
  width: 100%;
  height: auto;
  position: relative;
  display: block;
}

.SocialMediaButtons{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.Navigation{
  a{
    color: #000;
    &:hover{
      color: #888;
      text-decoration: none;
    }
  }
  ul{
    padding: 1rem;
    margin: 0;
    list-style-type: none;
  }
  li{
    display: inline-block;
    margin: 0 3rem;
  }
  @import 'Desktop', 'Mobile';
  @media (min-width: 767px) {
    .PageHeader-Desktop{
      display: block;
    }
    .PageHeader-Mobile{
      display: none;
    }
  }
}

.SortimentView {
  margin-top: 2rem;
  padding-bottom: 6rem;
  @include media-breakpoint-down(md) {
    margin-top: 1rem;
    .row {
      margin: -.5rem;
    }
    .SortimentCard {
      padding: .5rem;
    }
  }
}

.SortimentSearchbar {
  width: 30%;
  margin-bottom: 2rem;

  @include media-breakpoint-down(md) {
    width: 60%;
    margin-bottom: 1rem;
  }
  @include media-breakpoint-down(xs) {
    width: 80%;
  }
  margin-left:auto ;
  margin-right:auto ;


  input {
    border-radius: 3rem;
    padding: .5rem;
    border: none;
    background-color: #F2F2F2;
    margin-bottom: 0;

    &:focus {
      outline: none;
    }
  }
}

.SortimentCard {
  margin-bottom: 3rem;
}

.SortimentCard-Link {
  display: block;
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.SortimentCard-Body {
  padding: 1rem;
  @include media-breakpoint-down(lg) {
    padding: 0.5rem;
  }
}

.SortimentView-PaginationControls {
  padding-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}